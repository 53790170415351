import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";
import { useStaticTableOfContents } from "../queries";
import { FaAmazon, FaBookOpen, FaEnvelope, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import Seo from "../components/Seo";
import { useMash } from "../mash/Mash";
import mashConfig from "../../mash-config";
import { useSizeDocumentBody } from "../hooks/useSizeDocumentBody";

function BookIndex() {
  const { ready } = useMash();

  const toc = useStaticTableOfContents();

  useSizeDocumentBody();

  return (
    <>
      <Seo />
      <Helmet>
        <script src="https://components.getmash.com/youtube/youtube.js"></script>
        <script async src="https://components.getmash.com/donate/donate-btn.js"></script>
      </Helmet>
      
      <div className="page overflow-hidden flex flex-col h-full" style={{ visibility: !ready ? "hidden" : "visible" }}>
        <div
          className="header py-4 px-8 border font-bold text-sm sm:text-base flex items-center"
          style={{ minHeight: 64 }}
        >
          {toc.title}
        </div>
        <div className="grid-container grid grid-cols-1">
            <Link to="/">Ebook</Link>
            <Link to="/audiobook">Audiobook</Link>
        </div>
        <div className="overflow-auto pt-8 px-8">
          <div className="grid-container grid grid-cols-1 sm:grid-cols-normal h-full w-full max-w-6xl m-auto">
            <div className="grid-left-col flex flex-col items-center gap-6 mb-8 sm:mb-0">
              <div className="img-wrapper">
                <StaticImage src="../images/e21m_mockup.png" alt="Mash" height={350} />
              </div>
              <div className="buttons-wrapper flex flex-col gap-4 w-full justify-center items-center">
              <Link
                  className="flex gap-2 justify-center items-center py-2 px-8 border rounded-full w-full text-center block max-w-xs hover:bg-slate-100 active:bg-slate-200 text-black sans-serif font-medium"
                  to={toc.chapters[0].link || toc.chapters[0].sections[0].link}
                >
                  <FaBookOpen />
                  Start Reading
                </Link>
                <a
                  className="flex gap-2 justify-center items-center py-2 px-8 border rounded-full w-full text-center block max-w-xs hover:bg-slate-100 active:bg-slate-200 text-black sans-serif font-medium"
                  href="https://konsensus.network/product/bitcoin-everything-divided-by-21-million/"
                  target="_blank"
                >
                  <FaBookOpen />
                  Buy Paperback
                </a>
                <a
                  className="flex gap-2 justify-center items-center py-2 px-8 border rounded-full w-full text-center block max-w-xs hover:bg-slate-100 active:bg-slate-200 text-black sans-serif font-medium"
                  href="https://www.amazon.com/Bitcoin-Everything-divided-21-million/dp/9916697191/"
                  target="_blank"
                >
                  <FaAmazon />
                  Amazon
                </a>
                <mash-donate-btn class="w-full max-w-xs" handle={mashConfig.earnerID} size="sm" />
              </div>
              <div className="w-full border max-w-xs rounded-full" />
              <div className="flex items-center justify-evenly w-full max-w-xs">
                <a
                  className="flex gap-2 justify-center items-center p-2 border rounded-full w-full text-center block hover:bg-slate-100 active:bg-slate-200 text-black"
                  href="https://twitter.com/konsensusn"
                  target="_blank"
                  style={{ width: 42, height: 42 }}
                >
                  <FaTwitter />
                </a>

                <a
                  className="flex gap-2 justify-center items-center p-2 border rounded-full w-full text-center block hover:bg-slate-100 active:bg-slate-200 text-black"
                  href="mailto:info@konsensus.network.com"
                  target="_blank"
                  style={{ width: 42, height: 42 }}
                >
                  <FaEnvelope />
                </a>

                <a
                  className="flex gap-2 justify-center items-center p-2 border rounded-full w-full text-center block hover:bg-slate-100 active:bg-slate-200 text-black"
                  href="https://www.linkedin.com/company/getmash"
                  target="_blank"
                  style={{ width: 42, height: 42 }}
                >
                  <FaLinkedinIn />
                </a>
              </div>
            <div className="w-full border max-w-xs rounded-full" />
            <div>

            </div>
            </div>
            <div className="grid-right-col px-0 sm:px-8">
              <div className="desc mb-8">
                <h3 className="mt-0">Description</h3>
                <p>
                We're all chained to the unforgiving arrow of time. We're born, we live, and we get old. At least that's what we hope will happen. Then we die.
                </p>
                <p>
                None of us humans are millionaires in terms of hours, yet we sacrifice our most valuable and scarce asset just to stay alive in the hamster’s wheel one more day.
                </p>
                <p>
                In pursuit of saving time, Satoshi stumbled upon a discovery of a new, weightless, absolutely scarce element. The showstopper for each and every time thief. Element zero. Bitcoin.
                </p>
                <p>
                Bitcoin’s limited supply cap is the immovable object that enables us all to become an unstoppable force.
                </p>
              </div>
              <div className="border my-8" />
              <div className="toc">
              <mash-youtube-player resource="f97e8897-e181-42dc-904d-d3458d2a0d72" video-id="Ef1UGis6FuA" width="640" height="390"></mash-youtube-player>                <iframe src='https://www.justcast.com/widget/47357/episodes/1309748' width='100%' height='370px' scrolling='yes'></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookIndex;
